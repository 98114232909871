import React from "react";

const GeneralButton = ({ text, onclick, loaderComponent, loading }: { text: string | React.ReactElement; onclick?: () => void; loaderComponent?: React.ReactNode; loading?: boolean }) => {
  return (
    <div className="w-full">
      <button type="submit" className="bg-gradient-to-br bg-[#DAA53D] flex items-center shrink-0 justify-center rounded-lg text-white py-[10px] px-[24px] w-full font-medium" onClick={onclick}>
        {loading ? loaderComponent : text}
      </button>
    </div>
  );
};

export default GeneralButton;
