import { combineReducers } from "@reduxjs/toolkit";
import { CrispanQueryApi } from "api/QueryApi";
import AuthSlice from "features/authSlice";
import ModalSlice from "features/modalSlice";

export const rootReducer = combineReducers({
  auth: AuthSlice,
  modal: ModalSlice,
  [CrispanQueryApi.reducerPath]: CrispanQueryApi.reducer,
});
