import { createSlice } from "@reduxjs/toolkit";
import { CrispanQueryApi } from "api/QueryApi";

interface AdminDetails {
  adminPayload: any;
  token: string | null;
  websocketData: any;
  webSocketDataList: any;
  otpPayload: any;
}

const initialState = {
  adminPayload: null,
  token: null,
  websocketData: [],
  webSocketDataList: null,
  otpPayload: null,
} as AdminDetails;

const AuthSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    logOutUser: (state) => {
      state.token = null;
    },
    updateWebSocketData: (state, { payload }) => {
      state.websocketData = payload;
    },
    updateWebSocketDataList: (state, { payload }) => {
      state.webSocketDataList = payload;
    },
    setOtpPayload: (state, { payload }) => {
      state.otpPayload = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(CrispanQueryApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.adminPayload = payload;
      state.token = payload?.data?.access_token;
    });
  },
});

export const { logOutUser, updateWebSocketData, updateWebSocketDataList, setOtpPayload } = AuthSlice.actions;
export default AuthSlice.reducer;
