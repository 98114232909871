import React, { useState } from "react";
import AuthWrapper from "common/AuthWrapper";
import { replace, useFormik } from "formik";
import AuthInputFieldWrapper from "common/AuthInputFieldWrapper";
import { Email, LockSvg, PasswordOpen } from "assets/svgs/authSvgs";
import Logo from "assets/images/Logo.png";
import * as Yup from "yup";
import useAuth from "hooks/useAuth";
import ButtonLoader from "common/ButtonLoader";
import { Navigate, useNavigate } from "react-router-dom";
import { FaEyeSlash } from "react-icons/fa";

const NotFound = () => {
  const token = useAuth();
  const navigate = useNavigate();

  return token ? (
    <Navigate to={"/dashboard"} />
  ) : (
    <AuthWrapper>
      <div className="flex justify-between h-full w-full">
        <div className="flex flex-col lg:w-1/2 w-full justify-center px-[150px]">
          <p className="text-white text-[32px] mb-[27px]">Page not found</p>
          <p
            className="bg-primary w-fit text-white py-2 px-5 text-[1.3em] font-semibold cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          >
            Login
          </p>
          <div></div>
        </div>
        <div className="flex w-1/2 justify-end items-end p-[50px]">
          <div className="bg-white max-w-[276px] py-[10px] w-fit h-fit px-[16px] rounded-lg">
            <img src={Logo} alt="" />
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default NotFound;
