import { FormikValues } from "formik";
import moment from "moment";

export const getTextFieldFormikProps = (formik: FormikValues, key: string) => {
  const fieldProps = formik.getFieldProps(key);
  return {
    id: key,
    name: key,
    value: fieldProps?.value || "",
    helpertext: !!formik.touched && formik.errors[key],
    ...fieldProps,
  };
};

export function objectToFormData(data: any) {
  const fd = new FormData();
  for (const key in data) {
    fd.set(key, data[key]);
  }
  return fd;
}

export const splitDate = (date: string) => {
  return date.split("T")[0];
};

export const DateFormater = (date: any) => {
  return moment(date).format("ll");
};

export const portals: any = document.getElementById("portals");

export type Channel = "redux" | "general";

export interface Message {
  id: number;
  channel: Channel;
  userName: string;
  text: string;
}
