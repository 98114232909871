export const AuthEndPoints = {
  LOGIN: "auth/login",
};

export const UserEndpoints = {
  // admins
  GET_ADMINS: "admin/get-admins",
  GET_ADMIN_PROFILE: "admin/get-admin",
  CREATE_ADMIN: "admin/create-admin",
  DELETE_ADMIN: "admin/delete-admin/",
  AMDIN_UPDATE_PROFILE: "admin/update-admin",
  AMDIN_UPDATE_PASSWORD: "admin/update-password",

  // employees
  CREATE_EMPLOYEE: "admin/create-employee",
  UPDATE_EMPLOYEE: "admin/update-employee/",
  DELETE_EMPLOYEE: "admin/delete-employee/",
  GET_EMPLOYEES: "admin/get-employees",

  // department
  CREATE_DEPARTMENT: "admin/create-department",
  UPDATE_DEPARTMENT: "admin/update-department/",
  DELETE_DEPARTMENT: "admin/delete-department/",
  GET_DEPARTMENT: "admin/get-departments",

  //training
  CREATE_TRAINING: "admin/create-training",
  UPDATE_TRAINING: "admin/update-training/",
  DELETE_TRAINING: "admin/delete-training/",
  GET_TRAININGS: "admin/get-trainings",
};

export const DashboardEndpoints = {
  GET_DASHBOARD_STATS: "admin/get-dashboardstats",
};

export const AssessmentEndpoints = {
  CREATE_ASSESSMENT: "admin/create-exam",
  GET_ALL_ASSESSMENTS: "admin/get-exams",
};

export const DepartmentEndpoints = {
  CREATE_DEPARTMENT: "admin/create-department",
  GET_DEPARTMENT: "admin/get-department",
  DELETE_DEPARTMENT: "admin/delete-department/",
};

export const TrainingEndpoints = {
  GET_TRAININGS: "admin/get-trainings",
  CREATE_TRAINING: "admin/create-training",
};
