import { FetchArgs, createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/dist/query/react";
import { AuthEndPoints } from "constants/ApiEnumSets";
import { logOutUser } from "features/authSlice";
import { RootState } from "store/store";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  // baseUrl: "http://localhost:4000/v1/",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState)?.auth?.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
const staggeredBaseQueryWithBailOut = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    if (result.error?.status === 401) {
      api.dispatch(logOutUser());
    }

    return result;
  },
  {
    maxRetries: 0,
  }
);

export type Channel = "redux" | "general";

export const CrispanQueryApi = createApi({
  baseQuery: staggeredBaseQueryWithBailOut,
  reducerPath: "oyaRiderAdminAPi",
  tagTypes: ["ASSESSMENTS", "ADMIN", "FILE", "DEPARTMENTS", "DASHBOARD", "TRAININGS", "EMPLOYEES"],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: AuthEndPoints.LOGIN,
        // url: AuthEndPoints.SAMPLE_LOGIN,
        method: "POST",
        body,
      }),
    }),
    getOtp: builder.mutation({
      query: (body) => ({
        url: "auth/send-otp/",
        method: "POST",
        body,
      }),
    }),
    verifyOtp: builder.mutation({
      query: (body) => ({
        url: "auth/verify-otp/",
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: "auth/reset-password/",
        method: "PATCH",
        body,
      }),
    }),
    fileUpload: builder.mutation({
      query: (body) => ({
        url: "file/upload/",
        method: "PUT",
        body,
        // headers: { accept: "multipart/formdata" },
      }),
      invalidatesTags: ["FILE"],
    }),
  }),
});
