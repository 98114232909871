import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, REHYDRATE, REGISTER, PAUSE, PURGE, PERSIST } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { rootReducer } from "./reducer/reducer";
import { CrispanQueryApi } from "api/QueryApi";

const persistConfig = {
  storage,
  key: "root",
  debug: true,
  blacklist: [CrispanQueryApi.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export type RootStates = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REGISTER, REHYDRATE, PAUSE, PERSIST, PURGE],
      },
    }).concat(CrispanQueryApi.middleware),
});

export const persistor = persistStore(store);
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
